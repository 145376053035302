import HomeFooter from '@/Home/HomeFooter.vue';
import storyblok from '@/mixins/storyblok';
import { ROUTE_LIST_NAMES } from '@/config/router/appRoutes';
import { AuthPopups } from '@/Authentication/authentication.const';
import TrustPublication from '@/components/TrustPublication.vue';
import { computed, defineComponent, onMounted, onUnmounted } from '@vue/composition-api';
import useStructuredData from '@/shared/composables/useStructuredData';
import { SITE_URL } from '@/App.vue';
import PageFAQ from '@/components/PageFAQ.vue';
export default defineComponent({
    components: {
        PageFAQ,
        TrustPublication,
        HomeFooter
    },
    mixins: [storyblok],
    setup(_, context) {
        const { root } = context;
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        const schemaOrgInfo = computed(() => {
            // https://schema.org
            // https://www.searchenginejournal.com/google-site-names/467892/
            return {
                '@context': 'https://schema.org',
                '@graph': [
                    {
                        '@type': 'Organization',
                        name: 'We Meet B.V.',
                        url: `${SITE_URL}${root.$router.currentRoute.path}`,
                        logo: 'https://media.wemeet.nl/gallery/assets/home/logo_8_new_Y9FP-Fb5m.png',
                        contactPoint: {
                            '@type': 'ContactPoint',
                            telephone: '+31 20 204 4544',
                            contactType: 'Customer service'
                        },
                        sameAs: [
                            'https://www.instagram.com/wemeet.nl/',
                            'https://www.facebook.com/WeMeet.nl/',
                            'https://linkedin.com/company/wemeet-nl',
                            'https://twitter.com/we_meet_NL'
                        ]
                    },
                    {
                        '@type': 'WebSite',
                        name: 'We Meet',
                        url: SITE_URL,
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: `${SITE_URL}/search?q={search_term_string}`,
                            'query-input': 'required name=search_term_string'
                        }
                    }
                ]
            };
        });
        const makeAuthRedirect = () => {
            const query = root.$route.query;
            const state = query?.state;
            if (state && typeof state === 'string') {
                query.authPopup = AuthPopups[state];
            }
            globalThis.$router.replace({
                name: globalThis.$i18n.locale !== 'nl'
                    ? `en-${ROUTE_LIST_NAMES.MAIN}`
                    : ROUTE_LIST_NAMES.MAIN,
                query: {
                    ...query,
                    token: null,
                    email_token: null,
                    ['verify-email']: null,
                    ['verify-phone-with-link']: null,
                    redirect_url: null
                }
            });
        };
        /**
         * Update structured data to the page when FAQ is loaded
         * @param structuredData
         */
        const injectData = (structuredData) => {
            const data = schemaOrgInfo.value;
            data['@graph'].push(structuredData);
            injectStructuredData({
                '@context': 'https://schema.org',
                ...data
            });
        };
        const faqQuestions = computed(() => [
            {
                question: root.$t('home.faq.question_1.title'),
                answer: root.$t('home.faq.question_1.answer')
            },
            {
                question: root.$t('home.faq.question_2.title'),
                answer: root.$t('home.faq.question_2.answer')
            },
            {
                question: root.$t('home.faq.question_3.title'),
                answer: root.$t('home.faq.question_3.answer')
            },
            {
                question: root.$t('home.faq.question_4.title'),
                answer: root.$t('home.faq.question_4.answer')
            }
        ]);
        onMounted(() => {
            injectStructuredData(schemaOrgInfo.value);
            makeAuthRedirect();
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            faqQuestions,
            slug: 'home',
            injectData
        };
    }
});
