import { defineComponent } from '@vue/composition-api';
import { LOCATIONS } from '@/shared/const';
import { visibilityChanged } from '@/util/globalHelpers';
export default defineComponent({
    setup(_, context) {
        const { root } = context;
        const intentToSearch = () => {
            const searchInput = document.querySelector('input[type="search"]');
            root.$scrollTo('#hero_search', 1500, { offset: -100 });
            if (searchInput) {
                searchInput.focus();
                searchInput.select();
            }
        };
        return {
            locations: LOCATIONS,
            intentToSearch,
            visibilityChanged
        };
    }
});
